














































































































































































































































































import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { Resolve } from 'vue-di';
import DrugName from '~/components/DrugName.vue';
import LayoutModule from '~/store/layout';

@Component<ISIInfo>({
    components: {
        DrugName,
    },
})
export default class ISIInfo extends Vue {

    @Resolve
    public layout!: LayoutModule;

    // mounted() {
    //     this.layout.replaceProgramName(this.layout.configuration.programName, this.layout.previousProgramName)
    // }

    // @Watch('layout.configuration.programName', {deep: true})
    // public watchProgramName(programName: string) {
    //     this.layout.replaceProgramName(programName, this.layout.previousProgramName)
    // }
}
