// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".main-menu .v-input .v-label{color:#fff}.main-menu .header-icon{margin-right:10px}.main-menu .v-expansion-panel{border-bottom:1px solid rgba(0,0,0,.8)}.main-menu .v-expansion-panel-header--active,.main-menu .v-expansion-panel-header:hover{background:#363636}.main-menu .v-text-field__details{display:none}.importExportTextArea{border:1px solid #00395b;padding:10px}.increaseFont{font-size:16px}", ""]);
// Exports
exports.locals = {
	"primaryColor": "#00395b",
	"secondaryColor": "#003c60",
	"accentColor": "#c41230"
};
module.exports = exports;
