import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _89d26d30 = () => interopDefault(import('..\\pages\\drug-selection-page.vue' /* webpackChunkName: "pages_drug-selection-page" */))
const _3640b19a = () => interopDefault(import('..\\pages\\error.vue' /* webpackChunkName: "pages_error" */))
const _1657f734 = () => interopDefault(import('..\\pages\\rejection.vue' /* webpackChunkName: "pages_rejection" */))
const _14916b94 = () => interopDefault(import('..\\pages\\errors\\400.vue' /* webpackChunkName: "pages_errors_400" */))
const _149f8315 = () => interopDefault(import('..\\pages\\errors\\401.vue' /* webpackChunkName: "pages_errors_401" */))
const _14bbb217 = () => interopDefault(import('..\\pages\\errors\\403.vue' /* webpackChunkName: "pages_errors_403" */))
const _14c9c998 = () => interopDefault(import('..\\pages\\errors\\404.vue' /* webpackChunkName: "pages_errors_404" */))
const _4977a6d5 = () => interopDefault(import('..\\pages\\errors\\500.vue' /* webpackChunkName: "pages_errors_500" */))
const _b66eae02 = () => interopDefault(import('..\\pages\\need-a-card\\eligibility.vue' /* webpackChunkName: "pages_need-a-card_eligibility" */))
const _3a47cb54 = () => interopDefault(import('..\\pages\\need-a-card\\patient-information.vue' /* webpackChunkName: "pages_need-a-card_patient-information" */))
const _69551775 = () => interopDefault(import('..\\pages\\need-a-card\\success.vue' /* webpackChunkName: "pages_need-a-card_success" */))
const _bdccee06 = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages_index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('#{BasePath}#/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/drug-selection-page",
    component: _89d26d30,
    name: "drug-selection-page"
  }, {
    path: "/error",
    component: _3640b19a,
    name: "error"
  }, {
    path: "/rejection",
    component: _1657f734,
    name: "rejection"
  }, {
    path: "/errors/400",
    component: _14916b94,
    name: "errors-400"
  }, {
    path: "/errors/401",
    component: _149f8315,
    name: "errors-401"
  }, {
    path: "/errors/403",
    component: _14bbb217,
    name: "errors-403"
  }, {
    path: "/errors/404",
    component: _14c9c998,
    name: "errors-404"
  }, {
    path: "/errors/500",
    component: _4977a6d5,
    name: "errors-500"
  }, {
    path: "/need-a-card/eligibility",
    component: _b66eae02,
    name: "need-a-card-eligibility"
  }, {
    path: "/need-a-card/patient-information",
    component: _3a47cb54,
    name: "need-a-card-patient-information"
  }, {
    path: "/need-a-card/success",
    component: _69551775,
    name: "need-a-card-success"
  }, {
    path: "/",
    component: _bdccee06,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
